import React, { useState, useEffect } from "react";
import "./Style/style.css";
import { Link } from "react-router-dom";
import Pagination from "../SharedComponent/Pagination";
import ProspectSearch from "../SharedComponent/Prospecting/ProspectSearch";
import SidebarExtractContact from "../SharedComponent/SidebarExtractContact";
import SpecificUser from "../DetailedInfo/SpecificUser";
import BulkSearch from "../SharedComponent/BulkSearch";
import SavedListButton from "../SavedList/SavedListButton";
import {
  nameDemoData,
  tagsData,
  companyData,
  contentWriter,
} from "../../DemoData";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import MessageDialog from "../PersonalizedMessages/MessageDialog";
import { useSnackbar } from "notistack";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

const UserNameDemo = ({
  handleCSVFile,
  SpecificSearchBtn,
  today,
  currentPage,
  selectedLeads,
  handleLeadSelectionChange,
  unlockEmailDetails,
  wait,
  handleUnlockEmail,
  props,
  customSearch,
  handleLeadSelectAll,
  isCheckAll,
  handleLeadSelectionExportExcel,
  handleProfile,
  loadingProfile,
  specificUserDetails,
  active,
}) => {
  let arr = null;
  if (active === "name") {
    arr = nameDemoData;
  } else if (active === "tags") {
    arr = tagsData;
  } else if (active === "company") {
    arr = companyData;
  } else if (active === "contentWriter") {
    arr = contentWriter;
  }

  const [checked, setChecked] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleLinkedin = () => {
    setMessage("linkedin");
    setOpen(true);
  };
  // Adds or removes checkboxes from checked state array
  const handleCheckedBoxes = (e) => {
    const checkedBox = e.target.name;
    if (!checked.includes(checkedBox)) {
      setChecked((arr) => [...arr, checkedBox]);
    } else {
      setChecked(checked.filter((item) => item !== checkedBox));
    }
  };

  const selectAll = () => {
    setCheckAll(!checkAll);
  };

  useEffect(() => {
    console.log(checked);
  }, [checked]);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopup = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLink = (link) => {
    handleClose();
    window.open(link, "_blank");
  };
  return (
    <>
      <div>
        {" "}
        <div className="modal" id="bulkmodal">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
          <div className="modal-dialog">
            <div className="modal-message">
              <p>
                <i className="text-danger">Format to follow:</i> Ensure that the
                first column has the unique values you’re searching for.
                Download the sample below for better understanding.{" "}
              </p>
              <Link>
                <i className="text-danger text-decoration-underline">
                  Click here to download csv format
                </i>
              </Link>
            </div>
            <div className="modal-content">
              <form action="/upload" id="mydrop" className="dropzone">
                <div className="dz-message needsclick">
                  <button type="button" className="dz-button">
                    Drag and Drop File
                  </button>
                  <br />
                  <button type="button" className="dz-button">
                    OR{" "}
                  </button>
                  <br />
                  <span className="note needsclick">
                    <input type="file" accept=".csv" onChange={handleCSVFile} />
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="main-content-area pb-6 pt-2">
          <div className="main-wrapper container-fluid">
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <SpecificSearchBtn details={true} />
                {/* <div className="sidebar-search-for sidebar-widget pt-4 my-3">
                  <ProspectSearch customSearch={customSearch} />
                </div> */}
                <BulkSearch />
                <SidebarExtractContact />
              </div>
              <div style={{ marginTop: "2rem" }} className="col-md-8 col-lg-9">
                <div className="user-search-wrapper">
                  <div
                    className="detailed-search"
                    style={{ paddingLeft: "40px" }}
                  >
                    <div>
                      <small>Last Updated: {today}</small>
                    </div>
                  </div>
                </div>
                <div className="user-widget-box  my-3">
                  <div
                    style={{ marginTop: "2rem" }}
                    className="d-flex align-items-center justify-content-between py-3"
                  >
                    <div className="d-flex align-items-center ">
                      <input
                        className="ms-3 me-3"
                        type="checkbox"
                        // id="selectAll"
                        // name="selectAll"
                        onChange={selectAll}
                        // checked={isCheckAll}
                      />
                      <small className="">10 of 10</small>
                    </div>
                    <div className="d-flex">
                      <small
                        className="unlock-btn"
                        style={{ paddingTop: "1em" }}
                      >
                        Unlock Profile{" "}
                        <img
                          className="ps-3 pb-1"
                          src="/assets/images/Group 1617.png"
                          alt=""
                        />
                      </small>
                      <small
                        className="unlock-btn"
                        style={{ paddingTop: "1em" }}
                      >
                        Unlock Work Email{" "}
                        <img
                          className="ps-3  pb-1"
                          src="/assets/images/Group 1617.png"
                          alt=""
                        />
                      </small>
                      <button
                        onClick={handleLeadSelectionExportExcel}
                        className="export-btn"
                        disabled={selectedLeads.length === 0 ? true : false}
                      >
                        Export{" "}
                        <img
                          className="ps-3"
                          src="/assets/images/export.png"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="user-widget-box  my-3">
                  {
                    <div className="search-container mb-2">
                      {arr.map((data, index) => (
                        <div>
                          <div
                            className="search-user-container py-2"
                            key={`${currentPage}${index}`}
                          >
                            <input
                              className="box ms-3 me-3"
                              id={data.url}
                              type="checkbox"
                              name={data.name}
                              onChange={handleCheckedBoxes}
                              checked={checkAll}
                            />
                            <div className="search-author text-danger ">
                              <img
                                onError={(e) => {
                                  e.currentTarget.src =
                                    "assets/images/new_icons/ProfilePic.png";
                                }}
                                style={{ borderRadius: "50%" }}
                                src={
                                  data.profilePicture
                                    ? data.profilePicture
                                    : "/assets/images/new_icons/ProfilePic.png"
                                }
                                alt=""
                              />
                            </div>
                            <div className="search-user ps-3">
                              <p>
                                {Object.keys(data).length === 0 ? null : (
                                  <React.Fragment>
                                    {data.name ? data.name : data.lastName}
                                  </React.Fragment>
                                )}
                              </p>
                              <small className="d-block">
                                Works at{" "}
                                {Object.keys(data).length === 0
                                  ? null
                                  : data.job
                                  ? data.job
                                  : data.pastJob}
                              </small>
                              <small className="d-block">
                                {Object.keys(data).length === 0
                                  ? null
                                  : data.location}
                              </small>
                            </div>
                            <div className="linkedin-icon d-flex justify-content-end">
                              {data.url && (
                                <span>
                                  <Tooltip title="Tap for more actions" arrow>
                                    <Button>
                                      <img
                                        className="mr-4"
                                        src="/assets/images/linkedin1.png"
                                        alt=""
                                      />
                                    </Button>
                                  </Tooltip>
                                  <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={openPopup}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button",
                                    }}
                                    sx={{
                                      borderRadius: "50px",
                                    }}
                                  >
                                    <MenuItem
                                      role="link"
                                      href={data.url}
                                      target="_blank"
                                      onClick={() => handleLink(data.url)}
                                      sx={{
                                        display: "flex",
                                        gap: "4px",
                                        "&:hover": {
                                          backgroundColor: "#e0e0e0",
                                        },
                                      }}
                                    >
                                      <PersonOutlineOutlinedIcon
                                        style={{ color: "#7B7A80" }}
                                      />
                                      Open Linkedin Profile
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        handleClose();
                                        handleLinkedin();
                                      }}
                                      sx={{
                                        display: "flex",
                                        gap: "4px",
                                        "&:hover": {
                                          backgroundColor: "#e0e0e0",
                                        },
                                      }}
                                    >
                                      <EmailOutlinedIcon
                                        style={{ color: "#7B7A80" }}
                                      />
                                      Send Personalized Message
                                    </MenuItem>
                                    <MenuItem
                                      // onClick={handleClose}
                                      sx={{
                                        display: "flex",
                                        gap: "4px",
                                        "&:hover": {
                                          backgroundColor: "#e0e0e0",
                                        },
                                      }}
                                      onClick={async () => {
                                        handleClose();
                                        await navigator.clipboard.writeText(
                                          data.url
                                        );
                                        enqueueSnackbar(
                                          "Company Linkedin Copied!"
                                        );
                                      }}
                                    >
                                      <ContentCopyOutlinedIcon
                                        style={{ color: "#7B7A80" }}
                                      />
                                      Copy Linkedin Link
                                    </MenuItem>
                                  </Menu>
                                </span>
                              )}
                            </div>

                            <div className="search-email text-center">
                              <small>
                                {unlockEmailDetails?.map((spec) => (
                                  <span>
                                    {spec.index === `${currentPage}${index}` ? (
                                      spec.details.email === null ||
                                      spec.details.email === "Not Found" ? (
                                        spec.details.email
                                      ) : (
                                        <div className="d-flex align-items-center">
                                          <small className="ms-2">
                                            {spec.details.email}
                                          </small>
                                          <img
                                            className="ms-2"
                                            src="/assets/images/Vector.png"
                                            alt=""
                                          />
                                        </div>
                                      )
                                    ) : null}
                                  </span>
                                ))}
                              </small>
                              {wait === `${currentPage}${index}` ? (
                                <p>please wait...</p>
                              ) : (
                                <a
                                  href="#"
                                  onClick={(e) =>
                                    handleUnlockEmail(e, index, data, props)
                                  }
                                >
                                  <small className="d-block text-danger">
                                    Unlock Work Email
                                  </small>
                                </a>
                              )}
                            </div>
                            <p className="search-view-btn ">
                              <a
                                id={`closeBtn_${currentPage}${index}`}
                                className="btn button"
                                data-toggle="collapse"
                                href={`#collapseExample_${currentPage}${index}`}
                                data-target={`#collapseExample_${currentPage}${index}`}
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                onClick={(e) => handleProfile(index, data, e)}
                              >
                                Unlock Profile
                              </a>
                            </p>
                            <p>
                              <SavedListButton data={data} />
                            </p>
                          </div>
                          <div
                            style={{
                              background: "white",
                              borderRadius: "20px",
                              padding: "20px",
                            }}
                          >
                            <div
                              className="panel-collapse collapse in"
                              id={`collapseExample_${currentPage}${index}`}
                            >
                              {!loadingProfile ? (
                                specificUserDetails?.map((spec) => (
                                  <span>
                                    {spec.index === `${currentPage}${index}` ? (
                                      <span>
                                        <SpecificUser
                                          details={spec.details}
                                          proxyData={spec.proxyCurl}
                                          currentPage={currentPage}
                                          index={index}
                                        />
                                      </span>
                                    ) : null}
                                  </span>
                                ))
                              ) : (
                                <div>
                                  <section
                                    className="item-section"
                                    style={{ textAlign: "center" }}
                                  >
                                    Please Wait..
                                  </section>
                                </div>
                              )}{" "}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                </div>
                <div className="d-flex justify-content-center">
                  <Pagination
                    postsPerPage={10}
                    totalPosts={10 ? 10 : 1}
                    paginate={1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MessageDialog
        title={message}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default UserNameDemo;

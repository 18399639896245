import React, { useState } from "react";
import SearchBarSearch from "./SearchBarSearch";
import CompanyAndNameSearch from "./CompanyAndNameSearch";
import LinkedInSalesNavigatorSearch from "./LinkedInSalesNavigatorSearch";

const CombinedSearchBar = (props) => {
  const [activeSearch, setActiveSearch] = useState(1);

  return (
    <div
      className={
        props.isResultPage
          ? "searchbar-container mr-top"
          : "searchbar-container mr-dup-top"
      }
      data-cy="searchbar"
    >
      <div style={{ margin: "0.5rem" }} className="search-types mb-2">
        <button
          style={{ height: "fit-content", padding: "0.3rem", margin: "0.3rem" }}
          className={
            activeSearch === 1
              ? "type-search-button active-search-button"
              : "type-search-button"
          }
          onClick={() => setActiveSearch(1)}
        >
          Name, Email <br />
          or LinkedIn URL
        </button>
        <button
          style={{ height: "fit-content", padding: "0.3rem", margin: "0.3rem" }}
          className={
            activeSearch === 2
              ? "type-search-button active-search-button"
              : "type-search-button"
          }
          onClick={() => setActiveSearch(2)}
        >
          Person From <br /> Specific Company
        </button>
        <button
          disabled
          style={{ height: "fit-content", padding: "0.3rem", margin: "0.3rem" }}
          className={
            activeSearch === 3
              ? "type-search-button active-search-button"
              : "disabled-sales-button"
          }
          // onClick={() => setActiveSearch(3)}
        >
          {/* Sales Navigator <br />Query */}
        </button>
      </div>
      {activeSearch === 1 ? (
        <SearchBarSearch
          resultPage={props.isResultPage}
          setpopup={props.setpopup}
        />
      ) : activeSearch === 2 ? (
        <CompanyAndNameSearch setpopup={props.setpopup} />
      ) : (
        <LinkedInSalesNavigatorSearch setpopup={props.setpopup} />
      )}
    </div>
  );
};

export default CombinedSearchBar;

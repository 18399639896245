import React, { useContext, useEffect, useState } from "react";
import "./Style/style.css";
import { Link } from "react-router-dom";
import Pagination from "../SharedComponent/Pagination";
import ProspectSearch from "../SharedComponent/Prospecting/ProspectSearch";
import SidebarExtractContact from "../SharedComponent/SidebarExtractContact";
import BulkSearch from "../SharedComponent/BulkSearch";
import SpecificSearchBtn from "../SharedComponent/SpecificSearchBtn";
import Cookies from "js-cookie";
import SavedListButton from "../SavedList/SavedListButton";
import CombinedSearchBar from "../SharedComponent/CombinedSearchBar";
import { AppContext } from "../../Context";
import UserNameDemo from "./UserNameDemo";
import Modalpop from "../SharedComponent/Modal";
import { useSnackbar } from "notistack";
import Popup from "../Utils/Popup";
import { Spinner } from "../Utils/utils";
import { apiServer } from "../../config";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import MessageDialog from "../PersonalizedMessages/MessageDialog";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

export async function digestMessage(message) {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array

  // convert bytes to hex string
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
}

const SearchResult = (props) => {
  const { isDemo, resultBy } = useContext(AppContext);

  //setting location.state to empty object for demo
  if (isDemo) {
    props.location.state = {};
  }

  const [customSearch, setCustomSearch] = useState({
    location: null,
    industry: null,
    job_title: null,
    education: null,
    company_name: null,
    keywords: null,
    csv_file: null,
  });
  const [specificUserDetails, setSpecificUserDetails] = useState([
    { index: null, details: null, proxyCurl: null },
  ]);
  const [unlockEmailDetails, setUnlockEmailDetails] = useState([
    { index: null, details: null },
  ]);
  const [loadingProfile] = useState(true);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLeads, setCurrentLeads] = useState([]);
  const [myLeads, setMyLeads] = useState([]);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [searchedList, setSearchedList] = useState([]);
  const [selectedLeadHashKey, setSelectedLeadHashKey] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [selectedLeadIndex, setSelectedLeadIndex] = useState([]);
  const [searchId, setSearchId] = useState();
  const [popupVisible, setPopupVisible] = useState(false);
  const [httperror, setHttperror] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleLinkedin = () => {
    setMessage("linkedin");
    setOpen(true);
  };
  let today = new Date();
  const postsPerPage = 10;

  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  const paginate = (pageNumber) => {
    console.log("In paginate..........");
    setCurrentLeads([]);
    setCurrentPage(pageNumber);
    setCurrentLeads(
      searchedList && Array.isArray(searchedList)
        ? searchedList.slice(pageNumber * 10 - 10, pageNumber * 10)
        : 0
    );
  };

  today = dd + "/" + mm + "/" + yyyy;

  function handleError(status) {
    setLoading(false);
    setMyLeads([]);
    console.error(`Got HTTP Error ${status}`);
    if (!isDemo) {
      setHttperror(true);
      console.log("ErrorSearching for leads...searchResultTexAu");
    }
  }

  function handleInsufficientBalance(response) {
    console.error("Insufficient credits...", response);
    enqueueSnackbar("Insufficient Credits", { variant: "warning" });
  }

  useEffect(() => {
    (async () => {
      setSpecificUserDetails([]);
      setUnlockEmailDetails([]);

      if (props.location.pathname.includes("/social_url_search")) {
        if (!props.location.state) {
          console.warn("no state found");
          return;
        }

        console.log(`state data: ${props.location.state}`);

        const inputData = props.location.state.data;
        const endpoint = props.location.state.endpoint;

        setLoading(true);

        await sendForExecution(endpoint, inputData);
      }

      if (props.location.pathname.includes("/result_by_history_type1")) {
        try {
          const response = await fetch(
            apiServer + `/history/id/${props.location.state.details.id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${Cookies.get("user_token")}`,
              },
            }
          );
          let json_res = await response.json();
          setSearchId(json_res.id);
          // setTimeout(() => {
          console.log("174 Data loading..", json_res.id, json_res, loading);
          setLoading(false);
          setMyLeads(json_res.search_results);
          // }, 60000);
        } catch (err) {
          console.error("Error: ", err);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state.customSearch]);

  const sendForExecution = async (endpoint, inputData) => {
    console.log(`endpoint: ${endpoint}, inputData: ${inputData}`);
    console.log(inputData);

    try {
      const response = await fetch(apiServer + endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("user_token")}`,
        },
        body: JSON.stringify(inputData),
      });

      function handleUnAuthorized() {
        console.log("User is UnAuthorized in SearchResultTexAu");
        setPopupVisible(true);
        setLoading(false);
        setMyLeads([]);
      }

      async function handleSuccess(response) {
        let json = await response.json();
        if (!json || !json.execution_id) {
          handleError(response);
        }
      }

      switch (response.status) {
        case 200:
          return await handleSuccess(response);
        case 401:
          return handleUnAuthorized(response);
        default:
          return handleError(response);
      }
    } catch (err) {
      console.error(`Exception Getting Data from ${endpoint}`);
    }
  };

  useEffect(() => {
    paginate(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedList]);

  const handleCSVFile = (e) => {
    setCustomSearch({ ...customSearch, csv_file: e.target.files[0] });
  };

  const handleLeadSelectionChange = async (e, index) => {
    if (selectedLeads.length === currentLeads.length) {
      setIsCheckAll(!isCheckAll);
    }

    const { id, checked } = e.target;
    let hash_key = null;
    let tempJson = {};
    let index_json = {};

    setSelectedLeads([...selectedLeads, id]);
    hash_key = await digestMessage(id);

    tempJson[id] = hash_key;
    index_json[hash_key] = `${index}`;

    setSelectedLeadHashKey([...selectedLeadHashKey, tempJson]);
    setSelectedLeadIndex([...selectedLeadIndex, index_json]);

    if (!checked) {
      setSelectedLeads(selectedLeads.filter((item) => item !== id));
      setSelectedLeadHashKey(
        selectedLeadHashKey.filter((item) => item[id] !== hash_key)
      );
      setSelectedLeadIndex(
        selectedLeadIndex.filter((item) => item[hash_key] !== index)
      );
    }
  };

  const handleLeadSelectAll = async () => {
    setIsCheckAll(!isCheckAll);
    console.log("In select All", isCheckAll);
    if (isCheckAll) {
      setSelectedLeads([]);
      setSelectedLeadHashKey([]);
      setSelectedLeadIndex([]);
    } else {
      for (let i = 0; i < currentLeads.length; i++) {
        console.log("in loop.....", currentLeads.length);
        let hash_list_selected = {};
        let index_list_selected = {};
        let hash_key_i = await digestMessage(
          JSON.stringify(currentLeads[i].url)
        );
        console.log("hash_key_i", hash_key_i);
        hash_list_selected[currentLeads[i].url] = hash_key_i;
        index_list_selected[hash_key_i] = `${currentPage}${i}`;
        setSelectedLeadHashKey((prev) => [...prev, hash_list_selected]);
        setSelectedLeadIndex((prev) => [...prev, index_list_selected]);
      }

      setSelectedLeads(currentLeads.map((li) => li.url || li.profileLink));
    }
  };

  const handleLeadSelectionExportExcel = (e) => {
    e.preventDefault();

    const executeExport = async () => {
      function handleError() {
        enqueueSnackbar("Error Exporting File", { variant: "error" });
      }

      try {
        const inputData = {
          profile_urls: selectedLeads,
          hash_key_list: selectedLeadHashKey,
          search_id: searchId,
          search_index: selectedLeadIndex,
          export_type: "texAu",
        };

        const response = await fetch(apiServer + "/bulk_upload/export/excel", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("user_token")}`,
          },
          body: JSON.stringify(inputData),
        });

        async function handleSuccess(response) {
          const data = await response.json();
          if (!data) {
            return handleError();
          }
          console.log(data);

          const username = Cookies.get("username");
          enqueueSnackbar(
            <div>
              Hey {username}! We have started working on your file. <br />
              We will take some time to revert back. <br />
              Be rest assured, You'll get an email once it is ready.
              <br />
              After exporting, please ensure to refresh your credit for seamless
              service. Thank you!
            </div>,
            { variant: "info" }
          );
        }

        function handleUnAuthorized() {
          console.log("User is UnAuthorized in SearchResultTexAu");
          setPopupVisible(true);
        }

        switch (response.status) {
          case 200:
            return await handleSuccess(response);
          case 401:
            return handleUnAuthorized(response);
          case 402:
            return handleInsufficientBalance(response);
          default:
            return handleError(response);
        }
      } catch (err) {
        console.error("Error: ", err);
        handleError();
      }
    };

    if (!selectedLeads) {
      console.warn("No Selected Leads");
      return;
    }

    executeExport();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openPopup = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLink = (link) => {
    handleClose();
    window.open(link, "_blank");
  };
  console.log("isCheck....", selectedLeads);

  useEffect(() => {
    console.log("in searchText, myLeads useEffect");
    if (searchText !== "") {
      setSearchedList(
        myLeads.filter((data) => {
          return (
            data.name.toLowerCase().includes(searchText.toLowerCase()) ||
            data.location.toLowerCase().includes(searchText.toLowerCase()) ||
            data.job.toLowerCase().includes(searchText.toLowerCase())
          );
        })
      );
    } else {
      if (myLeads)
        setSearchedList(
          myLeads?.filter((data) => !!data.name || data.fullName)
        );
    }
  }, [searchText, myLeads]);

  if (isDemo) {
    for (let key in resultBy) {
      if (resultBy[key] === true) {
        return (
          <UserNameDemo
            handleCSVFile={handleCSVFile}
            SpecificSearchBtn={SpecificSearchBtn}
            customSearch={customSearch}
            today={today}
            handleLeadSelectAll={handleLeadSelectAll}
            isCheckAll={isCheckAll}
            handleLeadSelectionExportExcel={handleLeadSelectionExportExcel}
            selectedLeads={selectedLeads}
            loading={loading}
            currentPage={currentPage}
            handleLeadSelectionChange={handleLeadSelectionChange}
            unlockEmailDetails={unlockEmailDetails}
            props={props}
            loadingProfile={loadingProfile}
            specificUserDetails={specificUserDetails}
            active={
              key === "name"
                ? "name"
                : key === "tags"
                ? "tags"
                : key === "company"
                ? "company"
                : "contentWriter"
            }
          />
        );
      }
    }
  }

  return (
    <>
      {popupVisible ? <Modalpop /> : null}
      {httperror && (
        <Popup
          message="
            Oops! Looks like there's some issue. It is taking longer
            than usual to get information. Let me report this to my
            team. In the meantime, can you setup again using our
            extension?"
          closePopup={setHttperror}
        />
      )}
      <div>
        {" "}
        <div className="modal" id="bulkmodal">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
          <div className="modal-dialog">
            <div className="modal-message">
              <p>
                <i className="text-danger">Format to follow:</i> Ensure that the
                first column has the unique values you’re searching for.
                Download the sample below for better understanding.{" "}
              </p>
              <Link>
                <i className="text-danger text-decoration-underline">
                  Click here to download csv format
                </i>
              </Link>
            </div>
            <div className="modal-content">
              <form action="/upload" id="mydrop" className="dropzone">
                <div className="dz-message needsclick">
                  <button type="button" className="dz-button">
                    Drag and Drop File
                  </button>
                  <br />
                  <button type="button" className="dz-button">
                    OR{" "}
                  </button>
                  <br />
                  <span className="note needsclick">
                    <input type="file" accept=".csv" onChange={handleCSVFile} />
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="main-content-area pb-6 pt-2">
          <div className="main-wrapper container-fluid">
            <div className="row">
              <div className="col-md-4 col-lg-3">
                <SpecificSearchBtn details={true} />
                {/* <div
                  className="sidebar-search-for sidebar-widget pt-4 my-3"
                  style={
                    loading ? { opacity: "0.4", pointerEvents: "none" } : {}
                  }
                >
                  <ProspectSearch customSearch={customSearch} />
                </div> */}
                <BulkSearch />
                <SidebarExtractContact />
              </div>
              <div className="col-md-8 col-lg-9">
                {loading === false ? (
                  <div className="search-form4 d-flex mb-3">
                    <div className="input-group">
                      <div
                        className="input-placeholder"
                        style={{ width: "1000px", height: "50px" }}
                      >
                        <input
                          className="ps-3"
                          required
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                        {/* <div>
                          <CombinedSearchBar isResultPage={false} />
                        </div> */}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="user-search-wrapper">
                  <div
                    className="detailed-search"
                    style={{ paddingLeft: "40px" }}
                  >
                    <div>
                      <small>Last Updated: {today}</small>
                    </div>
                  </div>
                </div>
                <div className="user-widget-box  my-3">
                  <div className="d-flex align-items-center justify-content-between py-3">
                    <div className="d-flex align-items-center ">
                      <input
                        className="ms-3 me-3"
                        type="checkbox"
                        id="selectAll"
                        name="selectAll"
                        onChange={handleLeadSelectAll}
                        checked={isCheckAll}
                      />
                      <small className="">
                        <b>{currentLeads.length}</b> of{" "}
                        <b>{myLeads ? myLeads.length : 0}</b> Searched profiles
                      </small>
                    </div>
                    <div className="d-flex">
                      <small
                        className="unlock-btn"
                        style={{ paddingTop: "1em" }}
                      >
                        Unlock Profile{" "}
                        <img
                          className="ps-3 pb-1"
                          src="/assets/images/Group 1617.png"
                          alt=""
                        />
                      </small>
                      <small
                        className="unlock-btn"
                        style={{ paddingTop: "1em" }}
                      >
                        Unlock Work Email{" "}
                        <img
                          className="ps-3 pb-1"
                          src="/assets/images/Group 1617.png"
                          alt=""
                        />
                      </small>
                      <button
                        onClick={handleLeadSelectionExportExcel}
                        className="export-btn"
                        data-cy="export-btn"
                        disabled={selectedLeads.length === 0}
                      >
                        Export{" "}
                        <img
                          className="ps-3"
                          src="/assets/images/export.png"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="user-widget-box  my-3">
                  {loading === false ? (
                    <div className="search-container mb-2">
                      {currentLeads && currentLeads.length === 0 ? (
                        <div>
                          <h5>Records Not Found </h5>
                        </div>
                      ) : currentLeads ? (
                        currentLeads.map((data, index) => (
                          //     data.name !== "LinkedIn Member" ? (
                          <div>
                            <div
                              className="search-user-container py-2"
                              key={`${currentPage}${index}`}
                            >
                              <input
                                className="box ms-3 me-3"
                                id={data.det || data.profileLink}
                                type="checkbox"
                                name={data.name}
                                checked={selectedLeads.includes(
                                  data.details_url || data.profileLink
                                )}
                                onChange={(e) =>
                                  handleLeadSelectionChange(
                                    e,
                                    `${currentPage}${index}`
                                  )
                                }
                              />
                              <div className="search-author text-danger ">
                                <img
                                  onError={(e) => {
                                    e.currentTarget.src =
                                      "assets/images/new_icons/ProfilePic.png";
                                  }}
                                  style={{ borderRadius: "50%" }}
                                  src={
                                    data.profilePicture
                                      ? data.profilePicture
                                      : data.image
                                      ? data.image
                                      : "/assets/images/new_icons/ProfilePic.png"
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="search-user ps-3">
                                <p>
                                  {data.length === 0 ? null : (
                                    <React.Fragment>
                                      {data.name ? data.name : data.fullName}
                                    </React.Fragment>
                                  )}
                                </p>
                                {/*<p>{data.length === 0 ? null : data.fullName}</p>*/}
                                <small className="d-block">
                                  Works at{" "}
                                  {data.length === 0
                                    ? null
                                    : data.job
                                    ? data.job
                                    : data.occupation}
                                </small>
                                <small className="d-block">
                                  {data.length === 0 ? null : data.location}
                                </small>
                              </div>
                              <div className="linkedin-icon d-flex justify-content-end">
                                {data.details_url && (
                                  <span>
                                    <Tooltip
                                      title="Click to open linkedin"
                                      arrow
                                    >
                                      <Button
                                        data-cy="linkedin-link"
                                        aria-controls={
                                          open ? "basic-menu" : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          open ? "true" : undefined
                                        }
                                        onClick={handleClick}
                                        // href={
                                        //   data.details_url
                                        //     ? data.details_url
                                        //     : data.profileLink
                                        // }
                                        // target="_blank"
                                        // rel="noreferrer"
                                      >
                                        <img
                                          className="mr-4"
                                          src="/assets/images/linkedin1.png"
                                          alt=""
                                        />
                                      </Button>
                                    </Tooltip>
                                    <Menu
                                      id="basic-menu"
                                      anchorEl={anchorEl}
                                      open={openPopup}
                                      onClose={handleClose}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                      sx={{
                                        borderRadius: "50px",
                                      }}
                                    >
                                      <MenuItem
                                        role="link"
                                        href={data?.details_url}
                                        target="_blank"
                                        onClick={() =>
                                          handleLink(data?.details_url)
                                        }
                                        sx={{
                                          display: "flex",
                                          gap: "4px",
                                          "&:hover": {
                                            backgroundColor: "#e0e0e0",
                                          },
                                        }}
                                      >
                                        <PersonOutlineOutlinedIcon
                                          style={{ color: "#7B7A80" }}
                                        />
                                        Open Linkedin Profile
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          handleClose();
                                          handleLinkedin();
                                        }}
                                        sx={{
                                          display: "flex",
                                          gap: "4px",
                                          "&:hover": {
                                            backgroundColor: "#e0e0e0",
                                          },
                                        }}
                                      >
                                        <EmailOutlinedIcon
                                          style={{ color: "#7B7A80" }}
                                        />
                                        Send Personalized Message
                                      </MenuItem>
                                      <MenuItem
                                        sx={{
                                          display: "flex",
                                          gap: "4px",
                                          "&:hover": {
                                            backgroundColor: "#e0e0e0",
                                          },
                                        }}
                                        onClick={async () => {
                                          handleClose();
                                          await navigator.clipboard.writeText(
                                            data?.details_url
                                          );
                                          enqueueSnackbar(
                                            "Company Linkedin Copied!"
                                          );
                                        }}
                                      >
                                        <ContentCopyOutlinedIcon
                                          style={{ color: "#7B7A80" }}
                                        />
                                        Copy Linkedin Link
                                      </MenuItem>
                                    </Menu>
                                  </span>
                                )}
                              </div>
                              <div className="search-email text-left">
                                <small>
                                  {unlockEmailDetails?.map((spec) => (
                                    <span>
                                      {spec.index ===
                                      `${currentPage}${index}` ? (
                                        spec.details.email === null ||
                                        spec.details.email === "Not Found" ? (
                                          spec.details.email
                                        ) : (
                                          <div
                                            style={{
                                              wordBreak: "break-word",
                                              marginRight: "3px",
                                              paddingRight: "3px",
                                            }}
                                            className="d-flex text-left"
                                          >
                                            <small className="">
                                              {spec.details.email}
                                              <span
                                                style={{
                                                  fontSize: "0.6rem",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                <img
                                                  className="ms-1"
                                                  src="/assets/images/Vector.png"
                                                  alt=""
                                                />
                                              </span>
                                            </small>
                                          </div>
                                        )
                                      ) : null}
                                    </span>
                                  ))}
                                </small>
                              </div>
                              <p className="search-view-btn ">
                                <a
                                  className="btn button"
                                  data-toggle="collapse"
                                  href={
                                    "#collapseExample_" +
                                    `${currentPage}${index}`
                                  }
                                  data-target={
                                    "#collapseExample_" +
                                    `${currentPage}${index}`
                                  }
                                  role="button"
                                  aria-expanded="false"
                                  aria-controls="collapseExample"
                                >
                                  Unlock Profile
                                </a>
                              </p>
                              <p>
                                <SavedListButton data={data} />
                              </p>
                            </div>
                            <div
                              style={{
                                background: "white",
                                borderRadius: "20px",
                                padding: "20px",
                              }}
                            ></div>
                          </div>
                        ))
                      ) : (
                        <h5>Record Not Found</h5>
                      )}
                    </div>
                  ) : (
                    <Spinner />
                  )}
                </div>
                <div className="d-flex justify-content-center">
                  {loading === false ? (
                    <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={searchedList ? searchedList.length : 1}
                      paginate={paginate}
                      render={props.location.state.requestTexAu.searchTerm}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MessageDialog
        title={message}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default SearchResult;

import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import BulkSearch from "../SharedComponent/BulkSearch";
import AskJarvis from "../SharedComponent/AskJarvis";
import SidebarExtractContact from "../SharedComponent/SidebarExtractContact";
import SavedListButton from "../SavedList/SavedListButton";
import { AppContext } from "../../Context";
import backIcon from "../../images/back-union.png";
import Cookies from "js-cookie";
import ProspectSearch from "../SharedComponent/Prospecting/ProspectSearch";
import { useSnackbar } from "notistack";
import Emitter from "../Utils/emitter";
import { apiServer } from "../../config";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import MessageDialog from "../PersonalizedMessages/MessageDialog";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { mailOpener, openDialer } from "../../Utils/helper";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SendIcon from "@mui/icons-material/Send";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const SpecificUserInfo = () => {
  const { personLoading, customPersonInfo, customSearch } =
    useContext(AppContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [wait, setWait] = useState(null);
  const [allUnlockedEmails, setAllUnlockedEmails] = useState([""]);
  const [unlockEmailDetails, setUnlockEmailDetails] = useState([]);
  const location = useLocation();

  const isExtensionResults = false;
  const simpleSearchId = "";

  function handleUnAuthorized() {
    console.log("User is UnAuthorized");
    enqueueSnackbar("Please Logout and LogIn Again");
  }

  const handleUnlockEmail = async (e, index, data) => {
    setWait(data.details_url);
    e.preventDefault();

    if (isExtensionResults) {
      const emailVerificationRequest = {
        url: data.details_url,
        search_id: "extension_search",
        meta_data: null,
        category: "EXTENSION",
      };

      try {
        const emailVerificationResponse = await fetch(
          `${apiServer}/email/work_email`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("user_token")}`,
            },
            body: JSON.stringify(emailVerificationRequest),
          }
        );

        console.log(emailVerificationResponse);

        switch (emailVerificationResponse.status) {
          case 200:
            const workEmail = await emailVerificationResponse.json();
            checkExecutionStatusWorkEmail(workEmail?.id, e);
            break;
          case 402:
            enqueueSnackbar(
              "You have insufficient work email credit. Buy Credits to get details.",
              { variant: "warning" }
            );
            break;
          case 401:
            enqueueSnackbar("Please Logout and Login again.");
            break;
          default:
            console.error("Something went wrong...");
            enqueueSnackbar("Please Logout and Login again.");
        }
      } catch (error) {
        console.error("Error while fetching mail:" + error);
        enqueueSnackbar("Please Logout and Login again.");
      }
    } else {
      const id = location.state?.id;
      try {
        let emailVerificationRequest = {
          url: data.details_url,
          search_id: id,
          meta_data: data.meta_data,
          category: "REALTIME",
        };

        console.log("hash_key", emailVerificationRequest);
        const emailVerificationResponse = await fetch(
          apiServer + "/email/work_email",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("user_token")}`,
            },
            body: JSON.stringify(emailVerificationRequest),
          }
        );

        if (emailVerificationResponse.status === 401) {
          enqueueSnackbar("Please Logout and Login again.");
        }

        if (emailVerificationResponse.status === 402) {
          enqueueSnackbar(
            "You have insufficient work email credit. Buy Credits to get details."
          );
        }

        if (emailVerificationResponse.status === 200) {
          const resultEmail = await emailVerificationResponse.json();
          console.log("In workemail success: ", resultEmail);
          checkExecutionStatusWorkEmail(resultEmail?.id, e);
        }
      } catch (err) {
        console.error("Error: ", err);
        enqueueSnackbar(
          "Unable to reach Leadzen servers, please retry in a few minutes. " +
            "If this issue persists, please reach out to our customer support team ",
          { variant: "error" }
        );
      }
    }
  };

  const checkExecutionStatusWorkEmail = (searchId = null, e) => {
    if (!searchId) {
      console.log("searchId is Null");
      return;
    }

    let timeoutId;

    // check for results every 5 seconds, for 5 minutes
    const intervalId = setInterval(async () => {
      console.log("In interval.....", searchId);

      try {
        const response = await fetch(
          apiServer + `/email/work_email/${searchId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("user_token")}`,
            },
          }
        );

        console.log("response: ", response);

        function handleError() {
          if (timeoutId) clearTimeout(timeoutId);
          clearInterval(intervalId);
        }

        function handleCookieError(response) {
          // got cookie error - no need to check again, results will not change
          console.log("Response cookie error", response.statusText);
          handleError();
        }

        function handleNotFound() {
          console.log("Not Found Yet, Waiting...");
        }

        async function handleSuccess(response) {
          console.log("In success ", response);

          const json = await response.json();
          if (!json) {
            console.warn(`Invalid Data`);
            return handleError();
          }

          console.log("json: ", json);

          switch (json.status) {
            case "success": {
              console.log("json.status = success, json.search_results", json);

              setUnlockEmailDetails((prev) => [
                ...prev,
                {
                  index: json?.data?.url,
                  details: json,
                },
              ]);

              setAllUnlockedEmails([...allUnlockedEmails, json?.data?.url]);
              Emitter.emit("updateCredits", true);

              if (timeoutId) clearTimeout(timeoutId);

              clearInterval(intervalId);
              setWait(null);
              break;
            }
            case "running": {
              console.log("json.status = running");
              return;
            }
            case "failed": {
              console.log(">>>>>>>>>>>>>>>>>>> IN FAILED >>>>>>>>>>>>>>>>>>>");
              console.log(json);
              setUnlockEmailDetails((prev) => [
                ...prev,
                {
                  index: e.target.id,
                  details: json,
                },
              ]);
              setAllUnlockedEmails([...allUnlockedEmails, e.target.id]);
              Emitter.emit("updateCredits", true);
              if (timeoutId) clearTimeout(timeoutId);
              clearInterval(intervalId);
              setWait(null);

              break;
            }
            default: {
              console.log("json.status = failed/unknown");
              handleError();
            }
          }
        }

        switch (response.status) {
          case 200:
            return handleSuccess(response);
          case 401:
            clearInterval(intervalId);
            return handleUnAuthorized(response);
          case 403:
            clearInterval(intervalId);
            return handleCookieError(response);
          case 404:
            return handleNotFound();
          default:
            return handleError();
        }
      } catch (e) {
        console.error("Exception", e);
      }
    }, 5 * 1000);

    // stop checking after 5 minutes
    timeoutId = setTimeout(function () {
      console.error("record not found within 5 Min");
      clearInterval(intervalId);
      // TODO: log to sentry
    }, 5 * 60 * 1000);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLeadSelectionExportExcel = (e) => {
    e.preventDefault();

    const executeExport = async () => {
      function handleError(response = null) {
        console.error(`Error, Status Code: ${response?.status}`);
        enqueueSnackbar("There was an error Exporting your File", {
          variant: "error",
        });
      }

      try {
        const inputData = {
          profile_urls: [customPersonInfo.details_url],
          cookie: Cookies.get("user_linkedin_cookie")
            ? Cookies.get("user_linkedin_cookie")
            : null,
        };

        console.log("input data = ", JSON.stringify(inputData));

        const response = await fetch(apiServer + "/bulk_upload/export/excel", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get("user_token")}`,
          },
          body: JSON.stringify(inputData),
        });

        async function handleSuccess(response) {
          const data = await response.json();
          if (!data) {
            return handleError();
          }

          console.log(data);

          const username = Cookies.get("username");
          enqueueSnackbar(
            <div>
              Hey {username}! We have started working on your file. <br />
              We will take some time to revert back. <br />
              Be rest assured, You'll get an email once it is ready.
              <br />
              After exporting, please ensure to refresh your credit for seamless
              service. Thank you!
            </div>,
            { variant: "info" }
          );
        }

        function handleUnAuthorized(response = null) {
          console.log("User is UnAuthorized");

          enqueueSnackbar("Please Logout and LogIn Again");
        }

        function handleInsufficientBalance(response) {
          console.error("Insufficient credits...", response);
          enqueueSnackbar(
            "You have insufficient profile credit. Buy Credits to get details.",
            { variant: "warning" }
          );
        }

        switch (response.status) {
          case 200:
            return await handleSuccess(response);
          case 401:
            return handleUnAuthorized(response);
          case 402:
            return handleInsufficientBalance(response);
          default:
            return handleError(response);
        }
      } catch (err) {
        console.error("Error: ", err);
        handleError();
      }
    };

    executeExport();
  };
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState("");
  const handleClickOpen = async (email) => {
    setData(email);
    setOpen(true);
    setMessage("email");
  };

  const handleWhatsapp = async (phone) => {
    setData(phone);
    setOpen(true);
    setMessage("whatsapp");
  };

  const handleClose = () => {
    setOpen(false);
  };
  // const [anchorEl, setAnchorEl] = useState(null);
  // const openPopup = Boolean(anchorEl);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClosePopup = () => {
  //   setAnchorEl(null);
  // };
  const [anchorElArray, setAnchorElArray] = useState(new Array(30).fill(null));
  const openPopup = Boolean(anchorElArray);
  const handleClick = (event, index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = newAnchorElArray[index]
      ? null
      : event.currentTarget;
    setAnchorElArray(newAnchorElArray);
  };

  const handleClosePopup = (index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = null;
    setAnchorElArray(newAnchorElArray);
  };
  return (
    <>
      <div className="modal" id="bulkmodal">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
        <div className="modal-dialog">
          <div className="modal-message">
            <p>
              <i className="text-danger">Format to follow:</i> Ensure that the
              first column has the unique values you’re searching for. Download
              the sample below for better understanding.{" "}
            </p>
            <Link>
              <i className="text-danger text-decoration-underline">
                Click here to download csv format
              </i>
            </Link>
          </div>
          <div className="modal-content">
            <form action="/upload" id="mydrop" className="dropzone">
              <div className="dz-message needsclick">
                <button type="button" className="dz-button">
                  Drag and Drop File
                </button>
                <br />
                <button type="button" className="dz-button">
                  OR{" "}
                </button>
                <br />
                <span className="note needsclick">
                  <input type="file" accept=".csv" />
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="main-content-area pb-6 pt-2">
        <div className="main-wrapper container-fluid">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <BulkSearch data={true} />
              {/* <div className="sidebar-search-for sidebar-widget pt-4 my-3">
                <ProspectSearch customSearch={customSearch} />
              </div> */}
              <SidebarExtractContact data={true} />
            </div>
            <div className="col-md-8 col-lg-9">
              <div
                className="container-fluid my-3 d-flex align-content-center"
                style={{
                  height: "52px",
                  background: "#fff",
                  borderRadius: "5px",
                }}
              >
                <button
                  className="back-btn"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <img
                    src={backIcon}
                    alt="#"
                    style={{ width: "10px", height: "10px" }}
                  />
                  Back
                </button>
              </div>

              <div className="user-widget-box  my-3 ">
                <div className="search-container mb-2">
                  <div className="search-specific-user-container py-2">
                    <p className="profile-logo text-danger ml-5 mr-4">
                      <img
                        onError={(e) => {
                          e.currentTarget.src =
                            "/assets/images/new_icons/ProfilePic.png";
                        }}
                        src={
                          customPersonInfo?.image_url
                            ? customPersonInfo?.image_url
                            : "/assets/images/new_icons/ProfilePic.png"
                        }
                        srcSet="/assets/images/new_icons/ProfilePic.png"
                        alt="#"
                      />
                    </p>
                    <div className="search-user pe-3 ml-3">
                      <p style={{ minWidth: "max-content" }}>
                        {" "}
                        {customPersonInfo?.full_name
                          ? customPersonInfo?.full_name
                          : customPersonInfo?.name}{" "}
                      </p>
                      <small className="d-block">
                        {customPersonInfo?.jobs
                          ? customPersonInfo?.jobs[0]?.job_name +
                            " at " +
                            customPersonInfo?.jobs[0]?.company_name
                          : customPersonInfo?.summary}
                      </small>
                    </div>
                    {customPersonInfo?.profile_url !== "real_time_page_mca" ? (
                      <div className="linkedin-icon d-flex justify-content-end">
                        <a
                          href={
                            customPersonInfo?.profile_url
                              ? customPersonInfo?.profile_url
                              : customPersonInfo?.details_url
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="mr-1"
                            src="/assets/images/linkedin1.png"
                            alt=""
                          />
                        </a>
                      </div>
                    ) : null}

                    <div className="search-email text-center">
                      <small>
                        {wait === customPersonInfo?.details_url ? (
                          <p style={{ fontSize: "14px" }}>Please wait...</p>
                        ) : //check for succesfull url if it is not there show the normal
                        !allUnlockedEmails.includes(
                            customPersonInfo?.details_url
                          ) &&
                          customPersonInfo?.profile_url !==
                            "real_time_page_mca" ? (
                          <a
                            href="#"
                            id={customPersonInfo?.details_url}
                            onClick={(e) =>
                              handleUnlockEmail(e, 1, customPersonInfo)
                            }
                            className="text-danger"
                            style={{ fontSize: "14px" }}
                          >
                            {console.log(allUnlockedEmails)}
                            Unlock Work Email
                          </a>
                        ) : (
                          unlockEmailDetails?.map((spec) => (
                            <span>
                              {spec?.index === customPersonInfo?.details_url ? (
                                spec?.details?.status === "success" ? (
                                  <div
                                    style={{
                                      wordBreak: "break-word",
                                      marginLeft: "1em",
                                      maxWidth: "200px",
                                    }}
                                    className="d-flex  text-left"
                                  >
                                    <small
                                      className="d-flex flex-column"
                                      style={{ fontSize: "14px" }}
                                    />
                                    {spec?.details?.data?.email}
                                    <span
                                      style={{
                                        fontSize: "0.6rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {spec?.details?.data?.verified ? (
                                        <span>
                                          <img
                                            className="ms-1"
                                            src="/assets/images/new_icons/Verified.png"
                                            alt=""
                                            style={{
                                              width: "10.3px",
                                              height: "10.3px",
                                            }}
                                          />{" "}
                                          {/* Verified{" "} */}
                                        </span>
                                      ) : null}
                                    </span>
                                  </div>
                                ) : spec?.details?.status === "failed" ? (
                                  <small
                                    className=""
                                    style={{ fontSize: "14px" }}
                                  >
                                    {" "}
                                    Not Found{" "}
                                  </small>
                                ) : null
                              ) : null}
                            </span>
                          ))
                        )}
                      </small>
                    </div>

                    <p className="search-view-btn">
                      <button
                        onClick={handleLeadSelectionExportExcel}
                        className="export-btn"
                      >
                        Export
                        <img
                          className="ps-3 mr-1"
                          src="/assets/images/export.png"
                          alt=""
                          style={{ width: "30px", height: "15px" }}
                        />
                      </button>
                    </p>
                    <p className="search-close-btn">
                      {!customPersonInfo?.profile_url &&
                      !customPersonInfo?.details_url &&
                      customPersonInfo?.profile_url !== "real_time_page_mca" ? (
                        <span className="text-danger">
                          Not a valid lead. Can't save this
                        </span>
                      ) : (
                        <SavedListButton data={customPersonInfo} />
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{
                  background: "white",
                  borderRadius: "20px",
                  padding: "20px",
                  margin: "1em 0",
                }}
              >
                {personLoading ? (
                  <div>
                    <p>Please wait...</p>
                  </div>
                ) : Object.keys(customPersonInfo)?.length === 4 ? (
                  <p className="m-3">Records Not found</p>
                ) : (
                  <div>
                    <section className="item-section">
                      <div className="phone-child-div">
                        {/* Phones Section */}{" "}
                        <div>
                          {customPersonInfo &&
                          customPersonInfo?.phones?.length ? (
                            <h6> Associated Phone Numbers </h6>
                          ) : null}{" "}
                          {customPersonInfo && customPersonInfo?.phones?.length
                            ? customPersonInfo.phones.map((phone, index) => (
                                <div className="ms-2 d-flex align-items-center mb-3">
                                  <div className="d-flex align-items-center gap-4">
                                    <WhatsAppIcon
                                      sx={{
                                        fontSize: 18,
                                        color: "#4EC57E",
                                      }}
                                    />
                                    <Tooltip title="Tap for more actions" arrow>
                                      <Button
                                        variant="outlined"
                                        data-cy="linkedin-link"
                                        aria-controls={
                                          openPopup ? phone?.number : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          openPopup ? "true" : undefined
                                        }
                                        onClick={(e) => handleClick(e, index)}
                                        sx={{
                                          color: "black",
                                          border: "1px solid black",
                                          outline: "none",
                                          borderRadius: "50px",
                                        }}
                                      >
                                        {" "}
                                        {phone?.number}{" "}
                                      </Button>{" "}
                                    </Tooltip>
                                    <Menu
                                      id={phone?.number}
                                      anchorEl={anchorElArray[index]}
                                      open={Boolean(anchorElArray[index])}
                                      onClose={() => handleClosePopup(index)}
                                      MenuListProps={{
                                        "aria-labelledby": phone?.number,
                                      }}
                                      sx={{
                                        borderRadius: "50px",
                                      }}
                                    >
                                      <MenuItem
                                        sx={{
                                          display: "flex",
                                          gap: "4px",
                                          "&:hover": {
                                            backgroundColor: "#e0e0e0",
                                          },
                                        }}
                                        onClick={() =>
                                          openDialer(phone?.number)
                                        }
                                      >
                                        <OpenInNewIcon
                                          style={{ color: "#7B7A80" }}
                                        />
                                        Open Whatsapp
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          handleClose(index);
                                          handleWhatsapp(phone?.number);
                                        }}
                                        sx={{
                                          display: "flex",
                                          gap: "4px",
                                          "&:hover": {
                                            backgroundColor: "#e0e0e0",
                                          },
                                        }}
                                      >
                                        <WhatsAppIcon
                                          style={{ color: "#7B7A80" }}
                                        />
                                        Send Personalized Message
                                      </MenuItem>
                                    </Menu>
                                  </div>{" "}
                                  <div className="d-flex align-items-center">
                                    <a
                                      className="me-2 ms-2"
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        navigator.clipboard.writeText(
                                          phone?.number
                                        );
                                        enqueueSnackbar("Phone Number Copied!");
                                      }}
                                    >
                                      <img
                                        style={{ height: "10px" }}
                                        src="assets/images/new_icons/Copy.png"
                                        alt=""
                                      />
                                    </a>{" "}
                                  </div>{" "}
                                  {phone?.verified ? (
                                    <div
                                      style={{
                                        wordBreak: "break-word",
                                        marginRight: "3px",
                                        paddingRight: "3px",
                                      }}
                                      className="d-flex align-items-center"
                                    >
                                      <img
                                        className="ms-2"
                                        src="assets/images/new_icons/Verified.png"
                                        alt=""
                                        style={{
                                          width: "10.3px",
                                          height: "10.3px",
                                        }}
                                      />
                                      <small
                                        style={{
                                          fontSize: "0.6rem",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Verified
                                      </small>
                                    </div>
                                  ) : null}{" "}
                                </div>
                              ))
                            : null}{" "}
                        </div>
                        {/* Emails Section */}{" "}
                        <div>
                          {" "}
                          {customPersonInfo &&
                          customPersonInfo?.emails?.length ? (
                            <h6> Associated Email Addresses </h6>
                          ) : null}{" "}
                          {customPersonInfo && customPersonInfo?.emails?.length
                            ? customPersonInfo?.emails?.map((email) => (
                                <div
                                  className="ms-2 d-flex align-items-center mb-3"
                                  align="left"
                                >
                                  <div className="d-flex align-items-center">
                                    <Tooltip title="Tap for more actions" arrow>
                                      <Button
                                        data-cy="linkedin-link"
                                        aria-controls={
                                          openPopup ? "basic-menu" : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          openPopup ? "true" : undefined
                                        }
                                        onClick={handleClick}
                                        variant="outlined"
                                        sx={{
                                          color: "black",
                                          border: "1px solid black !important",
                                          outline: "none",
                                          borderRadius: "50px",
                                          minWidth: "min-content",
                                        }}
                                      >
                                        {email?.email}
                                      </Button>{" "}
                                    </Tooltip>
                                    <Menu
                                      id="basic-menu"
                                      anchorEl={anchorElArray}
                                      open={open}
                                      onClose={handleClosePopup}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                      sx={{
                                        borderRadius: "50px",
                                      }}
                                    >
                                      <MenuItem
                                        onClick={() => mailOpener(email.email)}
                                        sx={{
                                          display: "flex",
                                          gap: "4px",
                                          "&:hover": {
                                            backgroundColor: "#e0e0e0",
                                          },
                                        }}
                                      >
                                        <SendIcon
                                          style={{ color: "#7B7A80" }}
                                        />
                                        Open Email
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          handleClose();
                                          handleClickOpen(email?.email);
                                        }}
                                        sx={{
                                          display: "flex",
                                          gap: "4px",
                                          "&:hover": {
                                            backgroundColor: "#e0e0e0",
                                          },
                                        }}
                                      >
                                        <EmailOutlinedIcon
                                          style={{ color: "#7B7A80" }}
                                        />
                                        Send Personalized Email
                                      </MenuItem>
                                    </Menu>
                                    <img
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          email.email
                                        );
                                        enqueueSnackbar(
                                          "Email Address Copied!"
                                        );
                                      }}
                                      className="ms-2"
                                      style={{
                                        height: "10px",
                                        cursor: "pointer",
                                      }}
                                      src="/assets/images/new_icons/Copy.png"
                                      alt="Copy-button"
                                    />
                                  </div>
                                  {email?.verified ? (
                                    <div
                                      style={{
                                        wordBreak: "break-word",
                                        marginRight: "3px",
                                        paddingRight: "3px",
                                      }}
                                      className="d-flex align-items-center"
                                    >
                                      <img
                                        className="ms-2"
                                        style={{
                                          height: "10px",
                                        }}
                                        src="assets/images/new_icons/Verified.png"
                                        alt="copy-button"
                                      />
                                      <small
                                        style={{
                                          fontSize: "0.6rem",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Verified
                                      </small>
                                    </div>
                                  ) : null}{" "}
                                </div>
                              ))
                            : null}
                        </div>
                        <div>
                          {/*  Gender */}
                          {customPersonInfo && customPersonInfo?.gender ? (
                            <h6> Gender </h6>
                          ) : null}{" "}
                          <div className="ms-2 d-flex align-items-center mb-3">
                            <small>
                              {customPersonInfo && customPersonInfo?.gender
                                ? customPersonInfo?.gender
                                : null}
                            </small>
                          </div>
                          {/* DOB */}
                          {customPersonInfo && customPersonInfo?.dob ? (
                            <h6> Age </h6>
                          ) : null}
                          <div className="ms-2 d-flex align-items-center mb-3">
                            <small>
                              {customPersonInfo && customPersonInfo?.dob ? (
                                <span> {customPersonInfo?.dob} </span>
                              ) : null}
                            </small>
                          </div>
                          {/* Languages*/}
                          {customPersonInfo &&
                          customPersonInfo?.languages?.length ? (
                            <h6> Languages Known </h6>
                          ) : null}
                          {customPersonInfo &&
                          customPersonInfo?.languages?.length
                            ? customPersonInfo?.languages.map((language) => (
                                <div className="ms-2 d-flex align-items-center mb-3">
                                  <small> {language} </small>
                                </div>
                              ))
                            : null}
                          {/* Usernames */}
                          {customPersonInfo &&
                          customPersonInfo?.usernames?.length ? (
                            <h6> Associated Usernames </h6>
                          ) : null}
                          {customPersonInfo &&
                          customPersonInfo?.usernames?.length
                            ? customPersonInfo.usernames.map((username) => (
                                <div
                                  className="ms-2 d-flex align-items-center mb-3"
                                  align="left"
                                >
                                  <div className="d-flex align-items-center">
                                    <small className="ms-2"> {username} </small>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </section>

                    {/* Jobs */}
                    {customPersonInfo && customPersonInfo?.jobs?.length ? (
                      <section className="item-section">
                        <div style={{ width: "900px" }}>
                          <h4> Associated Jobs and Companies </h4>

                          {customPersonInfo && customPersonInfo?.jobs?.length
                            ? customPersonInfo?.jobs.map((job) => (
                                <div className="table-alignment container-fluid">
                                  <td> {job?.job_name} </td>
                                  <td> {job?.company_name} </td>
                                  <td>
                                    {job?.start_date}{" "}
                                    {job?.end_date
                                      ? `to ${job?.end_date}`
                                      : null}
                                  </td>
                                </div>
                              ))
                            : null}
                        </div>
                      </section>
                    ) : null}

                    {/* Education */}
                    {customPersonInfo &&
                    customPersonInfo?.educations?.length ? (
                      <section className="item-section">
                        <div style={{ width: "900px" }}>
                          <h4> Probable Education Associated </h4>

                          {customPersonInfo &&
                          customPersonInfo?.educations?.length
                            ? customPersonInfo?.educations?.map((education) => (
                                <div className="table-alignment container-fluid">
                                  <td> {education?.degree || "--"} </td>
                                  <td> {education?.school} </td>
                                </div>
                              ))
                            : null}
                        </div>
                      </section>
                    ) : null}

                    {/* Social Media */}
                    {customPersonInfo &&
                    (customPersonInfo?.probable_urls?.length ||
                      customPersonInfo?.locations?.length) ? (
                      <section className="item-section">
                        <div className="row">
                          <div
                            className="col-md-6"
                            style={{ overflowWrap: "break-word" }}
                          >
                            {/* URLS */}
                            {customPersonInfo?.probable_urls?.length ? (
                              <h4> Associated urls </h4>
                            ) : null}

                            <small className="ms-2">
                              {" "}
                              {customPersonInfo?.probable_urls?.length
                                ? customPersonInfo?.probable_urls?.map(
                                    (url) => (
                                      <div style={{ wordBreak: "break-word" }}>
                                        <a
                                          href={url}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {" "}
                                          {url}{" "}
                                        </a>
                                      </div>
                                    )
                                  )
                                : null}{" "}
                            </small>
                          </div>

                          <div className="col-md-6">
                            {/* Locations */}
                            {customPersonInfo &&
                            customPersonInfo?.locations?.length ? (
                              <h4> List of Locations </h4>
                            ) : null}

                            {customPersonInfo &&
                            customPersonInfo?.locations?.length
                              ? customPersonInfo?.locations?.map((location) => (
                                  <div>
                                    <p> {location} </p>
                                  </div>
                                ))
                              : null}
                          </div>
                        </div>
                      </section>
                    ) : null}
                  </div>
                )}
              </div>
              <div>
                {" "}
                <AskJarvis />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MessageDialog
        open={open}
        data={data}
        title={message}
        onClose={handleClose}
      />
    </>
  );
};

export default SpecificUserInfo;

import React from "react";
import "./Style/style.css";
import { Link } from "react-router-dom";
import Pagination from "../SharedComponent/Pagination";
import SpecificUser from "../DetailedInfo/SpecificUser";
import SidebarExtractContact from "../SharedComponent/SidebarExtractContact";
import ProspectSearch from "../SharedComponent/Prospecting/ProspectSearch";
import BulkSearch from "../SharedComponent/BulkSearch";
import SpecificSearchBtn from "../SharedComponent/SpecificSearchBtn";
import SavedListButton from "../SavedList/SavedListButton";
import { linkedinDemoData } from "../../DemoData";
import AskJarvis from "../SharedComponent/AskJarvis";

const LinkedUserDemo = ({
  handleCSVFile,
  currentPage,
  selectedLeads,
  handleLeadSelectionChange,
  unlockEmailDetails,
  handleProfile,
  handleUnlockEmail,
  specificUserDetails,
  postsPerPage,
  paginate,
}) => {
  return (
    <>
      <div className="modal" id="UpgradeModal">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="d-flex">
              <div className="pe-4">
                <img
                  style={{ height: "125px", width: "100px" }}
                  src="/assets/images/g10.png"
                  alt=""
                />
              </div>
              <div className="text-center">
                <p className="text-danger ">Oops</p>
                <p>
                  looks like you have insufficient credit to access this leads.
                  upgrade your plan now.
                </p>
                <button
                  style={{ background: "#FB3E3E" }}
                  className="btn text-white"
                >
                  {" "}
                  Upgrade Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="bulkmodal">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
        <div className="modal-dialog">
          <div className="modal-message">
            <p>
              <i className="text-danger">Format to follow:</i>
              Ensure that the first column has the unique values you’re
              searching for. Download the sample below for better understanding.
            </p>
            <Link>
              <i className="text-danger text-decoration-underline">
                Click here to download csv format
              </i>
            </Link>
          </div>
          <div className="modal-content">
            <form action="/upload" id="mydrop" className="dropzone">
              <div className="dz-message needsclick">
                <button type="button" className="dz-button">
                  Drag and Drop File
                </button>
                <br />
                <button type="button" className="dz-button">
                  OR
                </button>
                <br />
                <span className="note needsclick">
                  <input type="file" accept=".csv" onChange={handleCSVFile} />
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="main-content-area pb-6 pt-2">
        <div className="main-wrapper container-fluid">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <SpecificSearchBtn details={true} />
              {/* <div className="sidebar-search-for sidebar-widget pt-4 my-3">
                <ProspectSearch />
              </div> */}
              <BulkSearch />
              <SidebarExtractContact />
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="user-result" style={{ marginTop: "1.3rem" }}>
                <label htmlFor="results-container">Fetch Result for:</label>
                <div className="results-container">
                  {/* <p>{ props.location.state.reqJsonPipl.searchTerm }</p> */}
                  <p>https://www.linkedin.com/in/malharlakdawala/</p>
                </div>
              </div>
              <div className="user-widget-box  my-3">
                <div className="search-container mb-2">
                  {linkedinDemoData.map((data, index) => {
                    return (
                      <div>
                        <div className="search-user-container pt-4" key={index}>
                          <input
                            className="box ms-3 me-3"
                            id={`${currentPage}${index}`}
                            type="checkbox"
                            name={data.name}
                            checked={selectedLeads.includes(
                              `${currentPage}${index}`
                            )}
                            onChange={(e) =>
                              handleLeadSelectionChange(e, data, index)
                            }
                          />
                          <p className="search-author text-danger">
                            <img
                              style={{ borderRadius: "50%" }}
                              src="/assets/images/new_icons/ProfilePic.png"
                              alt=""
                            />
                          </p>
                          <div className="search-user pe-3 ml-4">
                            <p>
                              {data.names === undefined ||
                              data.names.length === 0
                                ? null
                                : data.names[0].display}
                            </p>
                            <small className="d-block">
                              Works at{" "}
                              {data.jobs === undefined || data.jobs.length === 0
                                ? null
                                : data.jobs[0].display}
                            </small>
                            <small className="d-block">
                              {data.addresses === undefined ||
                              data.addresses.length === 0
                                ? null
                                : data.addresses[0].display}
                            </small>
                          </div>
                          <div className="linkedin-icon d-flex justify-content-end">
                            <span>
                              <a href="#">
                                <img
                                  src="/assets/images/linkedin1.png"
                                  alt=""
                                />
                              </a>
                            </span>
                          </div>
                          <div className="search-email text-center">
                            <small
                            // className={
                            //   show[index] ? "d-block" : "d-block blur"
                            // }
                            >
                              {unlockEmailDetails?.map((spec) => (
                                <span>
                                  {spec.index === `${currentPage}${index}`
                                    ? spec.details.email
                                    : null}
                                </span>
                              ))}
                            </small>

                            <a
                              href="#"
                              id={index}
                              onClick={(e) => handleUnlockEmail(e, index, data)}
                            >
                              <small className="d-block text-danger">
                                Unlock Work Email
                              </small>
                            </a>
                          </div>
                          <p className="search-view-btn ">
                            <a
                              className="btn button"
                              data-toggle="collapse"
                              href={`#collapseExample_${currentPage}${index}`}
                              data-target={`#collapseExample_${currentPage}${index}`}
                              role="button"
                              aria-expanded="false"
                              aria-controls="collapseExample"
                              onClick={() => handleProfile(index, data)}
                            >
                              Unlock Profile
                            </a>
                          </p>

                          {/*<a href="#" onClick={clickSelect}>*/}
                          {/*  <p className="search-close-btn">*/}
                          {/*    <img*/}
                          {/*      src={*/}
                          {/*        selected*/}
                          {/*          ? "assets/images/Frame 543.png"*/}
                          {/*          : "assets/images/Group 1863.png"*/}
                          {/*      }*/}
                          {/*      alt=""*/}
                          {/*    />*/}
                          {/*  </p>*/}
                          {/*</a>*/}
                          <p>
                            <SavedListButton data={data} />
                          </p>
                        </div>
                        <div
                          style={{
                            background: "white",
                            borderRadius: "20px",
                            padding: "20px",
                          }}
                        >
                          <div
                            className="panel-collapse collapse in"
                            id={`collapseExample_${currentPage}${index}`}
                          >
                            {/* <div className="card card-body"> */}
                            {/*<SpecificUser details={data} />*/}
                            {/* </div> */}
                            {specificUserDetails?.map((spec) => (
                              <span>
                                {spec.index === `${currentPage}${index}` ? (
                                  <span>
                                    <SpecificUser
                                      details={spec.details}
                                      demo={true}
                                    />
                                  </span>
                                ) : null}
                              </span>
                            ))}{" "}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={1}
                  paginate={paginate}
                />
              </div>
              <AskJarvis />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkedUserDemo;

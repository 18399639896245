import React from "react";
import { Link } from "react-router-dom";
import ProspectSearch from "../SharedComponent/Prospecting/ProspectSearch";
import BulkSearch from "../SharedComponent/BulkSearch";
import SearchResultList from "./Optimized/SearchResultList";
import { motion } from "framer-motion";
import { fadeInFadeOut } from "../../Config/motions";

const BasicSearchResult = (props) => {
  return (
    <div>
      <div className="modal" id="UpgradeModal">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="d-flex">
              <div className="pe-4">
                <img
                  style={{ height: "125px", width: "100px" }}
                  src="/assets/images/g10.png"
                  alt=""
                />
              </div>
              <div className="text-center">
                <p className="text-danger ">Oops</p>
                <p>
                  looks like you have insufficient credit to access this leads.
                  upgrade your plan now.
                </p>
                <button
                  style={{ background: "#FB3E3E" }}
                  className="btn text-white"
                >
                  {" "}
                  Upgrade Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="bulkmodal">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
        <div className="modal-dialog">
          <div className="modal-message">
            <p>
              <i className="text-danger">Format to follow:</i>
              Ensure that the first column has the unique values youre searching
              for. Download the sample below for better understanding.
            </p>
            <Link>
              <i className="text-danger text-decoration-underline">
                Click here to download csv format
              </i>
            </Link>
          </div>
          <div className="modal-content">
            <form action="/upload" id="mydrop" className="dropzone">
              <div className="dz-message needsclick">
                <button type="button" className="dz-button">
                  Drag and Drop File
                </button>
                <br />
                <button type="button" className="dz-button">
                  OR
                </button>
                <br />
              </div>
            </form>
          </div>
        </div>
      </div>
      <motion.div className="main-content-area pb-6 pt-2" {...fadeInFadeOut}>
        <div className="main-wrapper container-fluid">
          <div className="row">
            {props?.location?.pathname?.includes("/RealtimePeopleSearch") ||
            props?.location?.pathname?.includes("/people/realtime/filter") ||
            props?.location?.pathname?.includes("/people/realtime/history") ? (
              ""
            ) : (
              <div className="col-md-4 col-lg-3">
                <BulkSearch />
                {/* <div className="sidebar-search-for sidebar-widget pt-4 my-3">
                  <ProspectSearch />
                </div> */}
              </div>
            )}
            <SearchResultList props={props} />
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default BasicSearchResult;

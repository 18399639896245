import React, { useEffect, useState } from "react";
import { getFetch, postFetch } from "../../Config/facade";
import BulkSearch from "../SharedComponent/BulkSearch";
import ProspectSearch from "../SharedComponent/Prospecting/ProspectSearch";
import { motion } from "framer-motion";
import { fadeInFadeOut } from "../../Config/motions";
import InputOrHistorySearchText from "../SearchResult/Optimized/InputOrHistorySearchText";
import SearchAndExportBar from "../SearchResult/Optimized/SearchAndExportBar";
import CompanySearchResultItems from "./CompanySearchResultItems";

const CompanySearch = (props) => {
  const [isExtensionResults, setIsExtensionResults] = useState(false);
  const [isExport, setExport] = useState(() => true);
  const [isCheckAll, setIsCheckAll] = useState(() => false);
  const [companyLeads, setCompanyLeads] = useState([]);
  const [companyLeadsCopy, setCompanyLeadsCopy] = useState([]);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState(false);
  console.log(
    "props?.location?.state?.searchInput",
    props?.location?.state?.search_term
  );
  console.log("props?.location?.state", props?.location?.state);
  useEffect(() => {
    (async () => {
      setLoading(true);

      if (props?.location?.pathname === "/CompanySearch") {
        setCompanyLeads([]);
        let interval;
        let uniquerItem = {};
        setHistory(false);
        const response = await postFetch(
          "/company_search_es/match/",
          props?.location?.state?.searchInput
        );

        async function handleSuccess() {
          const companyResponse = await getFetch(
            `/company_search_es/match/result/${response.id}`
          );

          switch (companyResponse.status) {
            case "success":
              let filteredCurrentLeads =
                //   [...new Set(companyResponse?.search_results)]
                companyResponse?.search_results?.filter(
                  (obj) => !uniquerItem[obj.id] && (uniquerItem[obj.id] = true)
                );
              console.log("filteredLeads", filteredCurrentLeads);
              setCompanyLeads(filteredCurrentLeads);
              setCompanyLeadsCopy(filteredCurrentLeads);
              setLoading(false);
              clearInterval(interval);
              console.log("search_results", companyResponse.search_results);
              break;
            case "running":
              return;
            case "failed":
              clearInterval(interval);
              setLoading(false);
              console.log("failed");
              break;
          }
        }

        const getCompanyLeads = () =>
          (interval = setInterval(() => handleSuccess(), 5 * 1000));
        if (response) getCompanyLeads();
      }

      if (props?.location?.pathname === "/CompanySearchHistory") {
        try {
          console.log("props in company resultssss:", props);
          setHistory(true);
          setCompanyLeads(props?.location?.state?.details?.search_results);
          setCompanyLeadsCopy(props?.location?.state?.details?.search_results);
          setLoading(false);
        } catch (error) {
          console.warn(error);
        }
      }
    })();
  }, [props?.location?.state]);

  console.log("::props", props);

  return (
    <motion.div className="main-content-area pb-6 pt-2" {...fadeInFadeOut}>
      <div className="main-wrapper container-fluid">
        <div className="row">
          <div className="col-md-4 col-lg-3">
            <BulkSearch />
            {/* <div className="sidebar-search-for sidebar-widget pt-4 my-3">
              <ProspectSearch />
            </div> */}
          </div>
          <div className="col-md-8 col-lg-9">
            {history ? (
              <InputOrHistorySearchText payload={props?.location?.state} />
            ) : (
              <InputOrHistorySearchText
                companySearchTerm={props?.location?.state?.searchInput}
              />
            )}
            <SearchAndExportBar
              props={props}
              isExtensionResults={isExtensionResults}
              isExport={isExport}
              isCheckAll={isCheckAll}
              currentLeads={companyLeads}
              setIsCheckAll={setIsCheckAll}
              setSelectedLeads={setCompanyLeads}
              setCurrentLeads={setCompanyLeads}
              selectedLeads={companyLeads}
              pageLeadsData={companyLeads}
              leadsCopy={companyLeadsCopy}
              // Used only to hide the export bar in company prospect search: remove when not required
              hideExportBar={true}
            />
            <CompanySearchResultItems
              data={companyLeads}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default CompanySearch;
